#root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
  text-align: center;
}

img {
  max-width: 60%;
}
